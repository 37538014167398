//const urlData = "http://localhost:4001/api/";
//const urlData = 'http://13.233.85.84:4001/api/'
//const urlData="https://bshakti.bssmfi.com/api/"
//const urlData="https://1150-59-153-2-67.ngrok-free.app/api/"
//const urlData = 'https://bshaktidev.bssmfi.com/api/'
//dev
const urlData = 'https://bshaktidev.bssmfi.com/api/'
//uat
// const urlData="https://bshaktiu.bssmfi.com/api/"
//prod
//const urlData="https://bshakti.bssmfi.com/api/"
//AWS IP
// const urlData="https://468a-103-174-199-200.ngrok-free.app/api/"
//Ngrok
// const urlData = 'https://78a8-103-174-199-200.ngrok-free.app/api/'

export default urlData
